import React, { Component } from 'react';
import { inject, observer} from "mobx-react";
import { withRouter } from "../../withRouter";
//import { withRouter } from 'react-router-dom';

import * as formats from '../../utilities/formats';

const CartTotals = inject("stores") (
    observer (
        class CartTotals extends Component {

            constructor(props) {
                super(props);
                this.props = props;
                this.urlAPI = process.env.REACT_APP_URL_API;

                this.storeAuth = this.props.stores.storeAuth;
                this.storeGeneral = this.props.stores.storeGeneral;
                this.storeUser = this.props.stores.storeUser;
                this.storeCart = this.props.stores.storeCart;
                this.storeDiscount = this.props.stores.storeDiscount;
                this.storeShipping = this.props.stores.storeShipping;
                this.storeVat = this.props.stores.storeVat;

                this.state = {
                    subtotal: 0,
                    shipping: 0,
                    total: 0,
                    vat: 0,
                    nrItems: this.storeCart.nrItems
                }
            }

            componentDidMount = async() => {
                const cartId = this.storeCart.cartId;

                let cart;
                let nrItems = this.props.nrItems;
                let subtotal = 0;
                let total = 0;
                let shipping = 0;
                let vat = 0;
                let shippingCountryCode;

                if (this.storeCart.cart && this.storeCart.cart.length === 0) {
                    cart = await this.storeCart.getCartItems(cartId);
                    nrItems = this.storeCart.calcNrItems;
                    //subtotal = await this.recalcTotals(cart);
                } else {
                    cart = await this.storeCart.getCartItems(cartId);
                    // subtotal = await this.recalcTotals(cart);
                }

                let cartAddresses = await this.storeCart.getCartAddresses();

                let countryDisplayValue;
                const user = await this.getUser();

                shippingCountryCode = this.storeShipping.getShippingCountryCode();
                countryDisplayValue = this.getCountryDisplayValue();

                subtotal = this.storeCart.calcTotal();

                shipping = await this.storeShipping.calcShipping(this.storeCart.cart);
                total = parseFloat(subtotal) + parseFloat(shipping);
                vat = this.storeVat.calcVat(total);

                const totals = this.updateCartTotals(subtotal, shipping, total, vat);
                
                subtotal = formats.displayPrice(subtotal);
                shipping = formats.displayPrice(shipping);
                total = formats.displayPrice(total);
                vat = formats.displayPrice(vat);

                this.setState({
                    user: user,
                    subtotal: subtotal,
                    total: total,
                    shipping: shipping,
                    vat: vat,
                    nrItems: nrItems,
                    countryDisplayValue: countryDisplayValue,
                    shippingCountryCode: shippingCountryCode
                })
            }
    
            componentDidUpdate = async(prevProps) => {
                const cartId = this.storeCart.cartId;

                let cart;
                let nrItems = this.props.nrItems;
                let subtotal = 0;
                let total = 0;
                let shipping = 0;
                let vat = 0;
                let cartAddresses;

                if (prevProps.nrItems !== this.props.nrItems || prevProps.countryCode !== this.props.countryCode || prevProps.hasShippingAddress !== this.props.hasShippingAddress) {
                    if ((this.storeCart.cart && this.storeCart.cart.length === 0) || this.storeCart.cart === null) {
                        cart = await this.storeCart.getCartItems(cartId);
                        cartAddresses = await this.storeCart.getCartAddresses();
                        nrItems = this.storeCart.calcNrItems;
                    } else {
                        // console.log('SbC update ...')
                        cart = await this.storeCart.getCartItems(cartId);
                        //const result = await this.recalcTotals(cart);
                        //cart = await this.storeCart.getCartItems(cartId);
                    }
                    
                    const user = await this.getUser();
                    const countryDisplayValue = this.getCountryDisplayValue();
    
                    subtotal = this.storeCart.calcTotal();

                    shipping = await this.storeShipping.calcShipping(this.storeCart.cart);
                    total = parseFloat(subtotal) + parseFloat(shipping);
                    vat = this.storeVat.calcVat(total);
    
                    const totals = this.updateCartTotals(subtotal, shipping, total, vat);

                    subtotal = formats.displayPrice(subtotal);
                    shipping = formats.displayPrice(shipping);
                    total = formats.displayPrice(total);
                    vat = formats.displayPrice(vat);
    
                    this.setState({
                        user: user,
                        subtotal: subtotal,
                        total: total,
                        shipping: shipping,
                        vat: vat,
                        nrItems: nrItems,
                        countryDisplayValue: countryDisplayValue,
                        shippingCountryCode: this.storeShipping.getShippingCountryCode()
                    })
                }
            }

            getUser = async() => {
                const id = this.storeAuth.user.id;
                const email = this.storeAuth.user.email;
                const user = await this.storeUser.getUserData(id, email);

                return user;
            }

            getCountryDisplayValue = () => {
                let value;
                let countryCode = this.storeShipping.getShippingCountryCode();

                const idx = this.storeGeneral.listCountries.findIndex(country => country.code == countryCode);
                if (this.storeGeneral.language === 'NL') {
                    value = this.storeGeneral.listCountries[idx].displayValue;
                } else {
                    if (countryCode === '00') {
                        value = this.storeCart.invoiceCountryCode;
                    } else {
                        if (countryCode !== null && countryCode !== 'undefined'  && countryCode !== undefined && countryCode !== '') {
                            value = this.storeGeneral.listCountries[idx].name;
                        } else {
                            value = '00';
                        }
                    }
                }

                return value;
            }

            gotoPage = (page) => {
                const url = "/" + page;
                this.props.navigate(url);
            }

            updateCartTotals = async(subtotal, shipping, total, vat) => {
                const totals = await this.storeCart.saveCartTotals(subtotal, shipping, total, vat);
            }
       
            render() {                         
                return (
                    <div
                        className = "wrapper-cart-totals"
                    >
                        <div
                            className = "wrapper-cart-total cart-subtotal"
                        >
                            <div
                                className = "cart-subtotal__label"
                            >
                                Subtotal
                            </div>
                            <div
                                className = "cart-subtotal__amount"
                            >
                                {this.storeCart.cart && this.storeCart.cart.length === 0
                                ?   <span>0,00</span>
                                :   <span>
                                        {this.state.subtotal}
                                    </span>
                                }
                            </div>
                        </div>
                        <div
                            className = "wrapper-cart-total cart-subtotal"
                        >
                            <div
                                className = "cart-subtotal__label"
                            >
                                Shipping costs
                            </div>
                            <div
                                className = "cart-subtotal__amount"
                            >
                                {this.storeCart.cart && this.storeCart.cart.length === 0
                                ?   <span>0,00</span>
                                :   <span>
                                        {this.state.shipping}
                                    </span>
                                }
                            </div>
                        </div>
                        <div
                            className = "wrapper-cart-total cart-total"
                        >
                            <div
                                className = "cart-subtotal__label"
                            >
                                Total (incl. VAT)
                            </div>
                            <div
                                className = "cart-subtotal__amount"
                            >
                                {this.storeCart.cart && this.storeCart.cart.length === 0
                                ?   <span>0,00</span>
                                :   <span>
                                        {this.state.total}
                                    </span>
                                }
                            </div>
                        </div>
                        <div
                            className = "wrapper-cart-total cart-subtotal"
                        >
                            <div
                                className = "cart-subtotal__label"
                            >
                                VAT
                            </div>
                            <div
                                className = "cart-subtotal__amount"
                            >
                                {this.storeCart.cart && this.storeCart.cart.length === 0
                                ?   <span>0,00</span>
                                :   <span>
                                        {this.state.vat}
                                    </span>
                                }
                            </div>
                        </div>
                        <div
                            className = "wrapper-cart-total cart-explanation"
                        >
                            {this.props.view === 'checkout' &&
                                <React.Fragment>
                                {(!this.storeShipping.hasShippingAddress && this.state.shippingCountryCode !== '00' && this.state.shippingCountryCode !== '' && this.state.shippingCountryCode !== undefined) &&
                                    <p>Shipping costs are based on shipping to {this.state.countryDisplayValue}</p>
                                }
                                {(this.storeShipping.hasShippingAddress && this.state.shippingCountryCode !== '00' && this.state.shippingCountryCode !== '') &&
                                    <p>Shipping costs based on shipping to {this.state.countryDisplayValue}</p>
                                }
                                {(this.storeShipping.hasShippingAddress && (this.state.shippingCountryCode === '00' || this.state.shippingCountryCode === '')) &&
                                    <React.Fragment>
                                    {this.storeCart.invoiceCountryCode === '' 
                                        ?   <p>No shipping country has been defined. Please select one in the shipping section.<br/>
                                            (Shipping costs are temporarily based on shipping to {this.storeGeneral.listSettings.defaultCountry})</p>
                                        :   <p>Shipping costs are based on shipping to {this.state.countryDisplayValue}</p>
                                    }
                                    </React.Fragment>
                                }
                                {(!this.storeShipping.hasShippingAddress && (this.state.shippingCountryCode === '00' || this.state.shippingCountryCode === '')) &&
                                    <p>No shipping country has been defined. Please select one in the invoice section.<br/>
                                    (Shipping costs are temporarily based on shipping to {this.storeGeneral.listSettings.defaultCountry})</p>
                                }
                                <span>Shipping costs will be updated when changing the <strong>shipping country</strong> above.</span>
                                </React.Fragment>
                            }
                            {this.props.view !== 'checkout' &&
                                <React.Fragment>
                                    {this.storeAuth.isLoggedin 
                                    ?   (this.state.shippingCountryCode !== '00' && this.state.shippingCountryCode !== '' && this.state.shippingCountryCode !== undefined) &&
                                        <p>Shipping costs are based on shipping to {this.state.countryDisplayValue}</p>  
                                    :   <span>Shipping costs are temporarily based on shipping to {this.storeGeneral.listSettings.defaultCountry}.<br/></span>
                                    }
                                    
                                    <span>Shipping costs will be updated when changing the <strong>shipping country</strong> during checkout</span>
                                    {this.storeAuth.isLoggedin 
                                    ?   <span> or by changing the country in "<a href="/account">My account</a>".</span>
                                    :   <span>.</span>
                                    }        
                                </React.Fragment>
                            }
                           
                        </div>
                    </div>
                )
            }
        }
    )
)

export default withRouter(CartTotals);
